/**
 * Common JS code
 *
 * @package Fractal-Analytics
 */

import * as Cookies from 'js-cookie';
import * as SimpleBar from 'simplebar/dist/simplebar';
import * as lity from '../vendor/lity';
import AOS from 'aos';

const commonScripts = {

	init() {

		this.rtequalheight();
		this.homeVideo();
		this.homeVideoOpen();
		this.contactSelect();
		//this.videoSpeaker();
		this.videoPopup();
		this.createSinglePageNav();
		this.stickyNavMenu();
		this.bottomStickyBar();
		this.aiNowMenuOnClick();
		this.aiLondon();
		this.memberLity();
		this.cabMenuOnClick();
		this.cabAgenda();
		this.cabSliders();
		this.toggleCabSpeaker();
		this.jobNotice();
		this.speakerPopup();
		this.popupForm();
		this.scrollToNext();
		this.pageScrollCTA();
		this.forresterScripts();
		this.infographicsScripts();
		this.valuesBlocks();
		this.caseStudy();
		this.stickyVideo();
		this.economistPage();

		$('ul.nav-tabs a').on(
			'click',
			function (e) {
				e.preventDefault();

				$(this).tab('show');
			}
		);

		if ($('select#hear-about-us').length) {
			$('select#hear-about-us').selectric(
				{
					disableOnMobile: false,
					nativeOnMobile: true
				}
			);
		}

	},
	/**
	 * Make video sticky at bottom when we scroll the page.
	 */
	stickyVideo: function () {

		if (!jQuery('.post-webinar-content').length) {
			return;
		}

		var iframe_video = jQuery('iframe[src*="https://www.youtube.com/embed/"], iframe[src*="https://player.vimeo.com/"],iframe[src*="https://player.vimeo.com/"]');
		var vimeo_video = jQuery('iframe[src*="https://player.vimeo.com/"],iframe[src*="https://player.vimeo.com/"]');

		if (iframe_video.length < 1) {
			return;
		}

		// var iframe_video = false;
		if (vimeo_video.length > 0) {

			var iframe = document.querySelector('iframe');
			var player = new Vimeo.Player(iframe);
			var show_bottom = false;

			player.on('play', function () {
				show_bottom = true;
			});
		}

		// Wrap iframe with the dev.
		iframe_video.wrap('<div class="iframe-parent-class"></div>');
		var iframe_close = jQuery('.sticky-video-close');
		var close_video = false;

		iframe_close.click(function () {
			close_video = true;
			iframe_video.removeClass('sticky-video').parent().height('auto');
			iframe_close.hide();
		});

		// Make each video visible at bottom.
		iframe_video.each(function (index) {

			/*Floating js Start*/
			var windows = jQuery(window);
			var iframeWrap = jQuery(this).parent();
			var iframe = jQuery(this);
			var iframeHeight = iframe.outerHeight();
			var iframeElement = iframe.get(0);

			windows.on('scroll', function () {

				if (close_video) {
					return;
				}

				if (!(show_bottom || 'yes' === iframe.attr('data-sticky'))) {
					return;
				}

				var windowScrollTop = windows.scrollTop();
				var iframeBottom = iframeHeight + iframeWrap.offset().top;
				var iframeTop = iframeWrap.offset().top;

				var viewportTop = $(window).scrollTop();
				var viewportBottom = viewportTop + $(window).height();

				if ((iframeTop > viewportBottom || windowScrollTop > iframeBottom) && windows.width() > 768) {
					iframeWrap.height(iframeHeight);
					iframe.addClass('sticky-video');
					iframe_close.show();
				} else {
					iframeWrap.height('auto');
					iframe.removeClass('sticky-video');
					iframe_close.hide();
				}
			});
		});
	},
	economistPage: function () {

		$('.show-in-mobile .economist-video__play-icon').click(function (e) {
			e.preventDefault();

			$('.economist-video__video-container').show();

			return false;
		});

		$('.economist-tabs .nav-tabs li a').click(function () {
			clearInterval(identity);
			$('.economist-tabs__progress-container').hide();
		});

		var economist_tab_pane = $('.economist-tabs__content-container .tab-pane');
		if ($(window).width() > 797 && economist_tab_pane.length) {

			var tab_height = 100;

			economist_tab_pane.each(function () {
				if ($(this).height() > tab_height) {
					tab_height = $(this).height();
				}
			});
			economist_tab_pane.css('height', tab_height);
		}

		$('.economist-tabs__image-popup').magnificPopup({
			type: 'image'
		});

		var element = document.getElementById('economist-tabs__progress-count');
		var width = 1;

		var scene = function () {

			if (width >= 100) {
				clearInterval(identity);
				var active_tab = $('.economist-tabs .nav-tabs .active');

				var top_of_element = active_tab.offset().top;
				var bottom_of_element = active_tab.offset().top + active_tab.outerHeight() - 70;
				var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
				var top_of_screen = $(window).scrollTop();

				if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {

					if (active_tab.next().length > 0) {
						active_tab.next().find('a').click();
					} else {
						$('.economist-tabs .nav-tabs li:first-child a').click();
					}
					$('.economist-tabs__progress-container').show();
				}

				width = 1;
				identity = setInterval(scene, 250);
			} else {
				width++;
				element.style.width = width + '%';
			}
		};

		if (jQuery("#economist-tabs__progress-count").length) {
			var identity = setInterval(scene, 250);
		}

		var slide_inner_items = function () {

			$('.economist-tabs__images').each(function () {

				var economist_tab_container = $(this).find('.economist-tabs__image-container');

				if (economist_tab_container.length < 2) {
					return;
				}

				economist_tab_container.fadeOut();
				var element = $(this).find('.economist-tabs__image-container.active');
				if (element.next().length > 0) {
					element.removeClass('active').next().addClass('active').fadeIn();
				} else {
					economist_tab_container.removeClass('active').first().addClass('active').fadeIn();
				}
			});
		};

		if (jQuery('.economist-tabs__images').length) {
			var inner_slider = setInterval(slide_inner_items, 10000);
		}
	},
	/**
	 * Case study scripts.
	 *
	 * @return {void}
	 */
	caseStudy: function () {
		if (!$('.case-study .case-study-option, .thought-leadership .case-study-option').length) {
			return;
		}

		$('.share-list').jsSocials(
			{
				shares: [
					'linkedin',
					'twitter',
					'facebook'
				]
			}
		);

		$('.case-study .case-study-option > li a').on(
			'click',
			function () {
				$(this).next('.share-list').slideToggle(
					'fast',
					function () {
						$(this).parent('.share-wrap').toggleClass('active');
					}
				);
			}
		);

		//thought-leadership social share expand horizontally
		$(' .thought-leadership .case-study-option > li a').on(
			'click',
			function () {
				$(this).parent('.share-wrap').toggleClass('active');
			}
		);

		$('.case-study .case-study-option > li .jssocials-shares .jssocials-share').on(
			'click',
			function () {
				$(this).parents('.share-list').slideToggle(
					'fast',
					function () {
						$(this).parents('.share-wrap').toggleClass('active');
					}
				);
			}
		);
	},

	/**
	 * Update Contact Page Form Select Dropdown Value.
	 *
	 * @return { void }
	 */
	contactSelect: function () {
		if ($('body').hasClass('page-contact-us')) {

			const $selectOption = $('#hear-about-us > option:first-child');

			if ('---' === $selectOption.html()) {
				$selectOption.empty();
			}
		}
	},

	/**
	 * Infographics Related Scripts.
	 *
	 * @return {void}
	 */
	infographicsScripts: function () {
		const seeLess = $('.home-banner-wrapper .info-graphics-list .see-less');
		const seeMore = $('.home-banner-wrapper .info-graphics-list .see-more');
		const seeLessMob = $('.home-banner-wrapper .info-graphics-list div[id*="see-more-"]');

		seeMore.on(
			'click',
			function () {
				$(window).trigger('resize');
				$('.custom-banners-cycle-slideshow').gp_cycle('pause');
			}
		);

		seeLess.on(
			'click',
			function () {
				$(window).trigger('resize');
				$('.custom-banners-cycle-slideshow').gp_cycle('resume');
			}
		);

		seeLessMob.on(
			'hidden.bs.collapse',
			function () {

				if (!seeLessMob.hasClass('in')) {
					$('.custom-banners-cycle-slideshow').gp_cycle('resume');
				}

				$(window).trigger('resize');
			}
		);

		seeLessMob.on(
			'shown.bs.collapse',
			function () {
				$('.custom-banners-cycle-slideshow').gp_cycle('pause');
				$(window).trigger('resize');
			}
		);
	},

	/**
	 * Forrester Page Scripts.
	 */
	forresterScripts: function () {
		if ($('body').hasClass('page-template-template-forrester')) {

			document.addEventListener(
				'wpcf7mailsent',
				function (event) {

					const formID = event.detail.contactFormId;

					// ID may different for stage and live site, so need to update on/before pushing to server.
					if ('21867' === formID || '22319' === formID || '23366' === formID) {

						// Hide page content and form on successful submit.
						$(document).find('.form-respond-popup').addClass('hide');

						if ('21867' === formID) {
							location = 'https://fractalanalytics.test/forrester/thank-you/?download=true';
						}

						if ('22319' === formID) {
							location = 'https://stage.fractal.ai/forrester/thank-you/?download=true';
						}

						if ('23366' === formID) {
							location = 'https://fractal.ai/forrester/thank-you/?download=true';
						}
					}

				},
				false
			);

			// Get query parameters from the URL.
			$.urlParam = function (name) {
				var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
				if (null === results) {
					return null;
				} else {
					return results[1] || 0;
				}
			};

			// Check if `download` present under parameter.
			if ($.urlParam('download')) {
				document.getElementById('force-download-pdf').click();
			}

		}
	},

	/**
	 * Contact form popup.
	 */
	popupForm: function () {

		$('.wpcf7-form').submit(function (e) {
			$('.ajax-loader').addClass('is-active');
		});

		const contactFormSubmitted = window.location.hash.substr(1);
		const invalidForm = $('.wpcf7-form.invalid');
		if ('undefined' !== typeof contactFormSubmitted && '' !== contactFormSubmitted && -1 !== contactFormSubmitted.indexOf('wpcf7') && invalidForm.length) {

			if (invalidForm.parents('.modal').length) {
				const modelId = invalidForm.parents('.modal').attr('id');
				$('#' + modelId).modal('show').css('display', 'block').addClass('in');
			} else {
				$('html, body').animate({
					scrollTop: $($('.wpcf7-form.invalid')).offset().top,
				}, 500
				);
			}
		}

		if ( $( 'body' ).hasClass( 'page-contact-us' ) || $( 'body' ).hasClass( 'page-ailondon2020' ) || $( 'body' ).hasClass( 'upcoming-shortcode-page' ) ) {

			$('.wpcf7-submit').after('<span class="ajax-loader active"></span>');

			$('.wpcf7-form').submit(function (e) {

				$('.ajax-loader').addClass('is-active');
				return true;
			})

			var captcha_div = $('.wpcf7-form-control-wrap.g-recaptcha-response');
			if ('undefined' !== typeof captcha_error && captcha_div.length >= 1) {
				setTimeout(function () {
					captcha_div.append(captcha_error);
				}, 3000);
			}
		}

		if ($('body').hasClass('page-ailondon2020')) {

			if (window.location.hash) {
				var hash = window.location.hash;
				if (navigator.userAgent.indexOf("Firefox") > 0) {
					setTimeout(function () {
						$('html, body').animate({
							scrollTop: $(hash).offset().top
						}, 10)
					}, 1);
				}

			}
		}

		//Trigger popup on Contact form submitted
		if ($('body').hasClass('page-contact-us-submitted')) {

			$('.mfp-close').on('click', function () {
				$('.page-contact-us-submitted .mfp-bg').hide();
				$('.page-contact-us-submitted .mfp-wrap').hide();
			})

			//Disable form submission on contact-us-submitted page
			$('.wpcf7-form').submit(function (e) {
				return false;
			})

			$('.wpcf7-form .wpcf7-submit').attr('disabled', true);
			$('.wpcf7-form .wpcf7-submit').addClass('disabled-submit', true);

		}

		const triggerPopup = $('a[href^="#popup_form"]');

		if (!triggerPopup.length) {
			return;
		}

		triggerPopup.on(
			'click',
			function (event) {
				event.preventDefault();
			}
		);

		triggerPopup.magnificPopup(
			{
				items: {
					src: '#popup_form',
					type: 'inline'
				}
			}
		);

		if ($('body').hasClass('upcoming-shortcode-page')) {
			if ('undefined' !== typeof upcoming_form_trigger) {
				$('#open_popup_form').trigger('click');
			}
		}
	},

	/**
	 * Scroll to next section.
	 */
	scrollToNext: function () {
		const scrollTo = $('a[href^="#scroll_to_next"]');

		if (!scrollTo.length) {
			return;
		}

		scrollTo.each(
			function () {
				$(this).on(
					'click',
					function (event) {
						event.preventDefault();

						$('html, body').animate(
							{
								scrollTop: $(this).parents('.home-banner-wrapper').next().offset().top - 70
							},
							700
						);

					}
				);
			}
		);
	},

	pageScrollCTA: function () {
		const pageScrollTo = $('.page-scroll-to a');

		if (!pageScrollTo.length) {
			return;
		}

		pageScrollTo.each(
			function () {
				$(this)
					.not('[href=""]')
					.not('[href="#"]')
					.not('[href="#0"]')
					.on(
						'click',
						function (event) {
							var target = $(this.hash);
							if (target.length > 0) {
								event.preventDefault();
								$('html, body').animate(
									{
										scrollTop: target.offset().top - 70
									},
									800
								);
							}

						}
					);
			}
		);
	},

	/**
	 * Equal height.
	 *
	 * @param container
	 */
	rtequalheight: function (container) {

		if ($(window).width() >= 767) {

			let currentTallest = 0;
			let currentRowStart = 0;
			let rowDivs = new Array();
			let $el;
			let topPosition = 0;

			$(container).each(function () {

				$el = $(this);
				$($el).height('auto');
				var topPostion = $el.position().top;

				if (currentRowStart != topPostion) {
					for (var currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
						rowDivs[currentDiv].height(currentTallest);
					}

					rowDivs.length = 0; // empty the array
					currentRowStart = topPostion;
					currentTallest = $el.height();

					rowDivs.push($el);
				} else {
					rowDivs.push($el);
					currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
				}

				for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
					rowDivs[currentDiv].height(currentTallest);
				}
			});
		}
	},

	homeVideo: function () {
		const mobileVideo = document.getElementById('mobile_video');

		jQuery(window).on('load', function () {
			if ($('.home-video-section .card-info-box').length > 0) {
				this.rtequalheight('.home-video-section .card-info-box');
			}

			if ($(window).width() >= 767) {
				$('.rt-video-post-feature-carousel .video-content').equalize();
				$('.post-card .post-information').equalize();
				$('.value-block').equalize();
			}

			// Force play the banner video on mobile.
			if ($(window).width() <= 767) {
				if (null !== mobileVideo) {
					mobileVideo.play();
				}
			}
		});

		jQuery(window).on('resize', function () {
			if ($('.home-video-section .card-info-box').length > 0) {
				this.rtequalheight('.home-video-section .card-info-box');
			}

			if ($(window).width() >= 767) {
				$('.rt-video-post-feature-carousel .video-content').equalize();
				$('.post-card .post-information').equalize();
				$('.value-block').equalize();
			}
		});
	},

	homeVideoOpen: function () {
		if ($('.home-banner-wrapper #openvideosection').length > 0) {
			$(document).on('click', '.home-banner-wrapper #openvideosection', function (event) {
				event.preventDefault();

				$('.home-video-section').slideDown(300, function () {
					if ($('.home-video-section .card-info-box').length > 0) {
						this.rtequalheight('.home-video-section .card-info-box');
					}

					var topOffset = '';

					if ($(window).width() >= 767) {
						topOffset = 70;
					} else {
						topOffset = 93;
					}

					$('html, body').animate({
						scrollTop: $('.home-video-section').offset().top - topOffset
					}, 'slow');

					$('.custom-banners-cycle-slideshow').gp_cycle('pause');

					AOS.refresh();
				});
			});
		}

		if ($('.home-video-section #closevideosection').length > 0) {
			$(document).on('click', '.home-video-section #closevideosection, .cycle-pager span', function (event) {
				event.preventDefault();

				$('.home-video-section').slideUp(300, function () {
					$('html, body').animate({
						scrollTop: $('.home-banner-wrapper').offset().top
					}, 'slow');
				});

				$('.custom-banners-cycle-slideshow').gp_cycle('resume');
			});
		}
	},

	// Show All Speaker Section
	videoSpeaker: function () {
		if ($('.video-speaker').length > 0) {
			$(document).on('click', '.toggle-speaker', function (event) {
				event.preventDefault();
				var toggleSpeaker = $(this);
				if (toggleSpeaker.hasClass('show-speaker')) {
					toggleSpeaker.removeClass('show-speaker');
					toggleSpeaker.addClass('hide-speaker');
					toggleSpeaker.html('Show less <i class="fa fa-minus-circle" aria-hidden="true"></i>');
					toggleSpeaker.parent().prev().slideDown(300);
				} else if (toggleSpeaker.hasClass('hide-speaker')) {
					toggleSpeaker.removeClass('hide-speaker');
					toggleSpeaker.addClass('show-speaker');
					toggleSpeaker.html('Show all speakers <i class="fa fa-plus-circle" aria-hidden="true"></i>');
					toggleSpeaker.parent().prev().slideUp(300);
				}
			});
		}
	},

	videoPopup: function () {
		$('.video-popup-link').magnificPopup({
			type: 'iframe',
			disableOn: 700,
			mainClass: 'mfp-fade',
			removalDelay: 160,
			preloader: !1,
			fixedContentPos: !1,
			iframe: {
				markup: '<div class="mfp-iframe-scaler">' + '<div class="mfp-close"></div>' + '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' + '<div class="mfp-pdf-link"><div class="video-cta"></div></div>' + '</div>'
			},
			callbacks: {
				elementParse: function (item) {
					item.download = item.el[0].dataset.download;
				},

				markupParse: function (template, values, item) {

					if ('' !== item.download) {
						template[0].lastChild.firstChild.innerHTML = '<a href="' + item.download + '" target="_blank" class="mfp-link" download>Download Presentation</a>';
					} else {
						template[0].lastChild.remove();
					}

				}
			}
		});
	},

	scroll_top: function () {
		$('html, body').animate({
			scrollTop: $('.career-list-wrap').offset().top - 60
		}, 2000);
	},

	/**
	 * Create navigation menu.
	 */
	createSinglePageNav: function () {

		var linkParent = $('#page-nav-list');
		var linkList = $('#page-nav-list li');
		var targetWrapper = $('div[data-scroll-target]');
		var listItems = [];
		var hasDoubleLine = false;

		if ((!targetWrapper.length && !linkParent.length) || false === (linkList.length === 0)) {
			return;
		}

		targetWrapper.each(function (index) {
			var text = $(this).find('.section-heading').text();
			var id = $(this).attr('id');
			listItems[index] = { text: text, id: id };
		});

		$.each(listItems, function (key, value) {

			let text = value.text.replace('&', '&<br/>');
			let item_class = 'list-item';
			if (text.includes('&') && !hasDoubleLine) {
				hasDoubleLine = true;
			}

			if ('Consumer Packaged Goods' === text) {
				text = 'CPG';
			}

			if ('Practitioner\'s Toolkits' === text) {
				text = '<a href="' + window.location.href + 'work-with-government/" target="_blank">Government</a>';
				item_class += ' arrow-up-right';
				value.id = '';
			}

			$('<li/>', {
				'data-target': value.id, class: item_class, html: text
			}).appendTo(linkParent);

		});

		if (hasDoubleLine) {
			linkParent.addClass('double-line-menu');
		}

	},

	/**
	 * ScrollTop Animation.
	 */
	aiNowMenuOnClick: function () {

		if (!$('#page-nav-list').length) {
			return;
		}

		let link = $('#page-nav-list li[data-target]');

		if (!link.length) {
			return;
		}

		$(link).on('click', function (event) {

			// Remove active class from all links.
			link.removeClass('active');

			// Add active class from current link.
			$(this).addClass('active');

			// Get target.
			let target = $('#' + $(this).data('target'));

			if (target.length) {

				event.preventDefault();

				$('html, body').animate({
					scrollTop: target.offset().top - 50
				}, 750);

			}

		});
	},

	aiNowOnScroll: function () {

		if (!$('#page-nav-list').length) {
			return;
		}

		let scrollPosition = $(document).scrollTop();
		let links = $('#page-nav-list li[data-target]');

		if (!links.length) {
			return;
		}

		links.each(function () {
			let currentLink = $(this);
			let refElement = $('#' + currentLink.data('target'));

			if (refElement.position().top - 35 <= scrollPosition && refElement.position().top + refElement.height() > scrollPosition) {
				links.removeClass('active');
				currentLink.addClass('active').removeClass('past');
			} else {
				currentLink.removeClass('active');
			}

			if (refElement.position().top + refElement.height() >= scrollPosition + 36) {
				currentLink.removeClass('past');
			} else {
				currentLink.addClass('past');
			}

		});

	},

	/**
	 * Bottom Sticky bar.
	 */
	bottomStickyBar: function () {
		var stickyBar = jQuery('.sticky-notification-bar');

		if (!stickyBar.length) {
			return;
		}

		var _this = this;
		if ('' === this.getCookie('bottom-sticky-bar')) {
			stickyBar.removeClass('sticky-hide');
		}

		jQuery('.clickdismisbar').on('click', function (e) {
			jQuery(this).closest('.sticky-notification-bar').addClass('sticky-hide');

			_this.setCookie('bottom-sticky-bar', 'false', 48);

			return false;
		});
	},

	/**
	 * Sticky navigation.
	 */
	stickyNavMenu: function () {

		var $navWrapper = $('.single-page-nav-wrapper');

		if (!$navWrapper.length) {
			return;
		}

		let topPosition = $navWrapper.position().top;
		let navWrapperHeight = $navWrapper.outerHeight();
		let i = $(window).width();
		let notBarHeight = navWrapperHeight;
		let refElement = $('#cpg');

		$navWrapper.wrap('<div class="sticky-wrapper" />');
		$('.sticky-wrapper').height(navWrapperHeight);

		if (i > 767) {
			notBarHeight = navWrapperHeight;
		} else {
			notBarHeight = $('.site-header').outerHeight();
		}

		$(window).on('scroll', function (scroll) {
			if (i > 767 && $(window).scrollTop() >= topPosition) {
				$navWrapper.addClass('sticky');
			} else {
				$navWrapper.removeClass('sticky');
			}

			if ($('body').hasClass('page-id-26625') || $('body').hasClass('page-id-30518')) {
				console.log($(window).scrollTop());
				if (refElement.position().top <= $(window).scrollTop()) {
					$('.chat-board').show();
				} else {
					$('.chat-board').hide();
				}
			}
		});
	},

	/**
	 * ScrollTop Animation.
	 */
	cabMenuOnClick: function () {

		if (!$('#cab-nav').length) {
			return;
		}

		let link = $('#cab-nav li');

		$(link).on('click', function (event) {

			// Remove active class from all links.
			link.removeClass('active');

			// Add active class from current link.
			$(this).addClass('active');

			// Get target.
			let target = $($(this).find('a').attr('href'));

			if (target.length) {

				event.preventDefault();

				$('html, body').animate({
					scrollTop: target.offset().top - 35
				}, 750);

			}

		});
	},

	cabMenuonScroll: function () {

		if (!$('#cab-nav').length) {
			return;
		}

		let scrollPosition = $(document).scrollTop();
		let links = $('#cab-nav li');

		links.each(function () {
			let currentLink = $(this);
			let refElement = $(currentLink.find('a').attr('href'));

			if (refElement.position().top - 36 <= scrollPosition && refElement.position().top + refElement.height() > scrollPosition) {
				links.removeClass('active');
				currentLink.addClass('active').removeClass('past');
			} else {
				currentLink.removeClass('active');
			}

			if (refElement.position().top + refElement.height() >= scrollPosition + 37) {
				currentLink.removeClass('past');
			} else {
				currentLink.addClass('past');
			}

		});

	},

	/**
	 * Cab landing Agenda section.
	 */
	cabAgenda: function () {

		if (!$('.resp-tabs-list').length && !$('#day-tabs').length && !$('.cab-2019-agenda').length) {
			return;
		}

		var windowWidth = $(window).width();
		$('#day-tabs li:first-child').addClass('active');
		$('.agenda-schedule:first-child').addClass('active');

		$('.resp-tabs-list li:not(.static-event)').filter(":first").addClass('active');
		$('.resp-tabs-container > div:not(.accordion-title, .static-event)').filter(":first").addClass('active');

		$('#day-tabs a').on('click touchstart', function (event) {

			event.preventDefault();
			$('#day-tabs li').removeClass('active');
			$('.agenda-schedule').removeClass('active');
			$(this).parent('li').addClass('active');

			var targetEl = $($(event.currentTarget).attr('href'));
			targetEl.addClass('active');
			targetEl.find('.resp-tabs-list li').removeClass('active');
			targetEl.find('.resp-tabs-list li:not(.static-event)').filter(":first").addClass('active');
			targetEl.find('.resp-tabs-container div').removeClass('active');
			targetEl.find('.resp-tabs-container div:not(.accordion-title, .static-event)').filter(":first").addClass('active');

			if (windowWidth < 768) {
				targetEl.find('.accordion-title:not(.static-event)').filter(":first").addClass('active');
				targetEl.find('.accordion-title:not(.static-event)').filter(":first").next().slideDown().siblings().slideUp('slow');
			}

		});

		$('.resp-tabs-list li:not(.static-event)').on('click', function () {
			let currentTarget = $(this).data();

			$('.resp-tabs-list li').removeClass('active');
			$('.resp-tabs-container > div').removeClass('active');
			$(this).addClass('active');
			$('#' + currentTarget.tab).addClass('active');

			//var scrollTarget = $( this ).parent();
			//
			//$( 'html, body' ).animate( {
			//	scrollTop: scrollTarget.offset().top - 75
			//}, 750 );
		});

		if (windowWidth < 768) {
			$('.accordion-title:not(.static-event)').filter(":first").removeClass('active');
			$('.resp-tabs-container > div:not(.accordion-title, .static-event)').filter(":first").removeClass('active');

			$('.resp-tabs-container').on('click touchstart', '.accordion-title:not(.static-event)', function (e) {

				e.preventDefault();

				// we would want to close the open accordion.
				if ($(this).hasClass('active')) {
					$(this).removeClass('active');
					$(this).next().slideUp('slow');
				} else {
					$('.accordion-title').removeClass('active');
					$(this).addClass('active');
					$(this).siblings().not().next().slideUp('slow');
					$(this).next().slideDown('slow', function () {
						$('html, body').animate({
							scrollTop: $(this).offset().top - 145
						}, 750);
					});
				}
				e.stopPropagation();
				return false;
			});
		}
	},

	contpost: function () {
		let e = $(window).width();
		let t = ($('.container'), $('.container').offset().left - 15);
		let a = $('.container').offset().left + 15;

		e >= 768 && ($('.owl-nav .owl-prev').css("left", t),
			$('.owl-nav .owl-next').css("right", t),
			$('.home-video-wrap .custom-container .hv-widget-area .media_image-4').css({
				width: "calc(100% - " + a + "px)"
			}));
	},

	// Speakers slider.
	cabSliders: function () {

		if ($('.members-carousel').length) {

			let e = $('.container').offset().left + 15;

			$('.members-carousel').owlCarousel({
				center: !1,
				items: 2,
				nav: !0,
				loop: !1,
				stagePadding: e,
				smartSpeed: 5e2,
				slideBy: 4,
				navText: ["<i class=\"fa fa-angle-left\" aria-hidden=\"true\"></i>", "<i class=\"fa fa-angle-right\" aria-hidden=\"true\"></i>"],
				margin: 30,
				responsive: {
					0: {
						items: 1
					},
					767: {
						items: 3
					},
					992: {
						items: 4
					}
				}
			});

			this.contpost();
		}

		if ($('.venue-carousel').length) {
			$('.venue-carousel').owlCarousel({
				animateIn: 'fadeIn',
				animateOut: 'fadeOut',
				autoplay: true,
				autoplayTimeout: 5000,
				dots: false,
				freeDrag: false,
				items: 1,
				loop: true,
				margin: 0,
				mouseDrag: false,
				nav: false,
				navText: ['', ''],
				pullDrag: false,
				smartSpeed: 250,
				stagePadding: 0,
				touchDrag: false,
				transitionStyle: 'fade'
			});
		}

		if ($('.address-slider').length) {

			if ($(window).width() <= 767) {

				$('.address-slider').owlCarousel({
					animateIn: 'fadeIn',
					animateOut: 'fadeOut',
					autoplay: false,
					dots: true,
					items: 1,
					loop: true,
					margin: 0,
					nav: false,
					navText: ['', ''],
					smartSpeed: 250,
					stagePadding: 0,
					transitionStyle: 'fade'
				});

			}

		}

		if ($('.ot-carousel, .ue-carousel').length) {
			$('.ot-carousel, .ue-carousel').owlCarousel({
				animateIn: 'fadeIn',
				animateOut: 'fadeOut',
				autoplay: true,
				autoplayTimeout: 5000,
				dots: false,
				freeDrag: false,
				items: 1,
				loop: true,
				margin: 0,
				mouseDrag: false,
				nav: false,
				navText: ['', ''],
				pullDrag: false,
				smartSpeed: 250,
				stagePadding: 0,
				touchDrag: false,
				transitionStyle: 'fade'
			});
		}

		if ($('.pics-gallery, .fade-in-gallery').length) {
			$('.pics-gallery, .fade-in-gallery').owlCarousel({
				animateIn: 'fadeIn',
				animateOut: 'fadeOut',
				autoplay: true,
				autoplayTimeout: 9000,
				dots: false,
				freeDrag: false,
				items: 1,
				loop: true,
				margin: 0,
				mouseDrag: false,
				nav: false,
				navText: ['', ''],
				pullDrag: false,
				smartSpeed: 250,
				stagePadding: 0,
				touchDrag: false,
				transitionStyle: 'fade'
			});
		}

		if ($('.rt-video-post-feature-carousel').length) {

			let e = $('.container').offset().left + 15;

			$('.rt-video-post-feature-carousel').owlCarousel({
				center: false,
				items: 2,
				nav: true,
				loop: false,
				stagePadding: e,
				smartSpeed: 250,
				navText: [" ", " "],
				margin: 30,
				slideBy: 1,
				responsive: {
					0: { items: 1, slideBy: 1 }, 767: { items: 2, slideBy: 1 }, 992: { items: 3, slideBy: 1 }
				}
			});

			$('.rt-video-post-feature-carousel .owl-nav .owl-prev').css('left', 0);
			$('.rt-video-post-feature-carousel .owl-nav .owl-next').css('right', 0);
		}

		if ($('.economist-page').length && $('.owl-carousel').length) {

			$('.show-in-mobile .cab-post-feature.column-posts').owlCarousel({
				center: false,
				items: 2,
				nav: true,
				loop: false,
				stagePadding: 50,
				smartSpeed: 250,
				navText: [" ", " "],
				margin: 30,
				slideBy: 1,
				responsive: {
					0: { items: 1, slideBy: 1 }, 767: { items: 2, slideBy: 1 }, 992: { items: 3, slideBy: 1 }
				}
			});
		}

	},

	toggleCabSpeaker: function () {
		$(document).on('click', '.toggle-speaker', function (event) {
			event.preventDefault();
			var video_speaker = $(this).parents('.video-speaker').length;
			var toggleSpeaker = $(this);
			if (toggleSpeaker.hasClass('show-speaker')) {

				toggleSpeaker.removeClass('show-speaker').addClass('hide-speaker').html('Hide speakers <i class="fa fa-minus-circle" aria-hidden="true"></i>');
				if (video_speaker >= 1) {
					toggleSpeaker.parent().prev().slideDown(300);
				} else {
					toggleSpeaker.parent().next('#sessionSpeaker').find('li.hidden-speaker').addClass('visible');
				}

			} else if (toggleSpeaker.hasClass('hide-speaker')) {

				toggleSpeaker.removeClass('hide-speaker').addClass('show-speaker').html('Show all speakers <i class="fa fa-plus-circle" aria-hidden="true"></i>');
				if (1 <= video_speaker) {
					toggleSpeaker.parent().prev().slideUp(300);
				} else {
					toggleSpeaker.parent().next('#sessionSpeaker').find('li.hidden-speaker').removeClass('visible');
				}

			}

		});
	},

	/**
	 * Show / Hide notice on Job openings.
	 *
	 * By default open notice window, after close set cookie.
	 * Check cookie before opening notice window.
	 */
	jobNotice: function () {
		const jobNotice = $('#job-legal-notice');

		if (!jobNotice.length) {
			return;
		}

		let isset_cookie = Cookies.get('fa_job_notice');

		if (!isset_cookie) {
			jobNotice.addClass('active');
		}

		$('#close-notice').on('click', function () {
			jobNotice.removeClass('active');

			Cookies.set('fa_job_notice', true, { path: '/' });
		});
	},

	aiLondon: function () {
		var toggleIntro = $('.toggle-intro');

		if (!toggleIntro.length) {
			return;
		}

		var e = $('.container').offset().left + 15;

		$(".owl-carousel.agenda").owlCarousel({
			center: false,
			items: 3,
			nav: true,
			stagePadding: e,
			smartSpeed: 350,
			navText: [" ", " "],
			margin: 30,
			slideBy: 3,
			responsive: {
				0: { items: 1, slideBy: 1 }, 767: { items: 2, slideBy: 2 }, 992: { items: 3, slideBy: 3 }
			}
		});

		toggleIntro.each(function () {
			var _this = $(this);

			_this.on('click', function (event) {
				event.preventDefault();

				if (!_this.parent().parent().hasClass('active')) {

					_this.parent().parent().addClass('active');
					_this.parent().parent().children('.show-intro-ondemand').slideDown();
					_this.html('Show less <i class="fa fa-angle-up" aria-hidden="true"></i>');

				} else {

					_this.parent().parent().removeClass('active');
					_this.parent().parent().children('.show-intro-ondemand').slideUp();
					_this.html('Read more <i class="fa fa-angle-down" aria-hidden="true"></i>');

				}

			});
		});
	},

	memberLity: function () {
		$(document).on('click', '.members-wrapper .member-popup', lity);

		$(document).on('lity:open', function (event, instance) {
			setTimeout(function () {

				$(document).find('.lity-container .simplebar-member-content').attr('id', 'simplebar_init');
				new SimpleBar(document.getElementById('simplebar_init'));

			}, 1000);
		});

		$(document).on('lity:close', function (event, instance) {
			$(document).find('.lity-container .simplebar-member-content').attr('id', '');
		});
	},

	speakerPopup: function () {
		const windowWidth = $(window).outerWidth();

		const speakerPopupLaunch = document.querySelectorAll('.single-event .speaker-popup .speaker-popup-launch');

		speakerPopupLaunch.forEach(function (element) {
			$(element).on('click', function () {

				if ($('.speaker-popup').hasClass('active')) {
					$('.speaker-popup').removeClass('active').find('.speaker-meta, .speaker-image').addClass('speaker-popup-launch');
				}

				$(this).parents('.speaker-popup').addClass('active').find('.speaker-meta, .speaker-image').removeClass('speaker-popup-launch');
				$('body').addClass('speaker-popup-active');

				if (767 > windowWidth) {
					$('html, body').animate(
						{
							scrollTop: $('.speaker-popup.active').offset().top - 90
						},
						700
					);
				}
			});
		});

		const speakerPopup = $('.single-event .speaker-popup');

		speakerPopup.on('click', '.speaker-popup-dismiss', function () {
			$(this).parent('.speaker-popup').removeClass('active').find('.speaker-meta, .speaker-image').addClass('speaker-popup-launch');
			$('body').removeClass('speaker-popup-active');
		});
	},

	captchaValidation: function () {

	},

	valuesBlocks: function () {
		const valuesBlocks = $('#values_blocks');

		if (!valuesBlocks.length) {
			return;
		}

		const windowWidth = $(window).width();
		const body = $('body');
		const valueBlockItems = valuesBlocks.find('.block-item');

		if (windowWidth >= 768) {
			$('.block-item .expand').on('click', function (event) {
				event.preventDefault();
			});

			valueBlockItems.each(function () {
				$(this).find('.value-block').on('click', function (event) {

					valueBlockItems.removeClass('active');
					body.removeClass('value-overlay');

					$(this).parents('.block-item').addClass('active');
					body.addClass('value-overlay');

					event.preventDefault();
				});
			});

			$('.block-close').on('click', function (event) {
				$(this).parent('.block-item').removeClass('active');
				body.removeClass('value-overlay');

				event.preventDefault();
			});

			$(document).on('keyup', function (evt) {
				if ($('.block-item').hasClass('active')) {
					if (27 === evt.keyCode) {

						$('.block-item').removeClass('active');
						body.removeClass('value-overlay');

					}
				}
			});

			$('.common-overlay').on('click', function (event) {
				$('.block-item').removeClass('active');
				$('body').removeClass('value-overlay');

				event.preventDefault();
			});
		}

		if (windowWidth <= 767) {
			$('.block-item .expand').on('click', function (event) {
				event.preventDefault();
			});

			$('.value-block').each(function () {
				$(this).on('click', function () {
					$(this).toggleClass('is-flipped');
				});
			});
		}
	},

	/**
	 * Get Cookie.
	 *
	 * @param cname Cookie Name
	 *
	 * @return {string}
	 */
	getCookie: function (cname) {
		var name = cname + "=";
		var decodedCookie = decodeURIComponent(document.cookie);
		var cookies = decodedCookie.split(';');
		for (var i = 0; i < cookies.length; i++) {

			var cookie = cookies[i];
			while (cookie.charAt(0) == ' ') {
				cookie = cookie.substring(1);
			}

			if (cookie.indexOf(name) == 0) {
				return cookie.substring(name.length, cookie.length);
			}
		}
		return "";
	},
	/**
	 * Set Cookie.
	 *
	 * @param cname       Cookie Name
	 * @param cvalue      Cookie Value
	 * @param exipreHours Expiry Hours
	 */
	setCookie: function (cname, cvalue, exipreHours) {
		var d = new Date();
		d.setTime(d.getTime() + (exipreHours * 60 * 60 * 1000));
		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}
};

export { commonScripts };
