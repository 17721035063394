/**
 * Main scripts, loaded on all pages.
 *
 * @package FractalAnalytics
 */

import { commonScripts } from './components/common-scripts';
import AOS from 'aos';

import '../sass/main.scss';

window.$ = window.$ || jQuery;

AOS.init(
	{
		offset: 200,
		duration: 1000,
		easing: 'ease-out-back',
		delay: 100
	}
);

import './vendor/lity';
import './vendor/bootstrap';
import './vendor/jquery.magnific-popup.js';
import './vendor/jssocials';
import './vendor/owl.carousel';
import './vendor/icheck';
import './vendor/jquery.selectric';
import './vendor/typed.min';
import './vendor/jquery.mixitup.min';
import './vendor/core';

commonScripts.init();

import './components/job-opening';

if ($('#page-nav-list').length || $('#cab-nav').length) {

	// Trigger function on scroll.
	jQuery(window).on(
		'scroll',
		function () {
			commonScripts.aiNowOnScroll();
			commonScripts.cabMenuonScroll();
		}
	);

}
