/**
 * As this file was minified file, formatted code so it can be easy to update code as this file is handles some previous core functionality.
 */
(function ($) {
	function eligheight() {
		setInterval(function () {
			var e = -1;
			$(".navbar .navbar-nav > li.dropdown .dropdown-menu li .elementy-ul li a").each(function () {
				e = e > $(this).height() ? e : $(this).height()
			}), $(".navbar .navbar-nav > li.dropdown .dropdown-menu li .elementy-ul li a").each(function () {
				$(this).height(e)
			})
		}, 100)
	}

	function eligheight1() {
		var e = -1;
		$(".meet-our-experts-slider .item .inner.cab-agenda").each(function () {
			e = e > $(this).height() ? e : $(this).height()
		}), $(".meet-our-experts-slider .item .inner.cab-agenda").each(function () {
			$(this).height(e)
		})
	}

	function carousallength() {
		setInterval(function () {
			1 == $(".home-lrdthgh-slider.owl-carousel .owl-item").length ? ($(".home-lrdthgh .owl-stage").addClass("one-owl-stage"), $(".home-lrdthgh-slider.owl-carousel .owl-item").addClass("one-owl-item")) : ($(".home-lrdthgh .owl-stage").removeClass("one-owl-stage"), $(".home-lrdthgh-slider.owl-carousel .owl-item").removeClass("one-owl-item"))
		}, 100)
	}

	function carousalmaxheight() {
		$('.home-lrdthgh-slider.thought-leadership .owl-item .media .media-left .img-wrapper img').on('load', function () {
			var e = $(window).width(), t = -1;
			$(".home-lrdthgh-slider.owl-carousel .owl-item").each(function () {
				t = t > $(this).height() ? t : $(this).height()
			}), (e <= 650 || e >= 500) && $(".home-lrdthgh-slider.thought-leadership .owl-item .media .media-left .img-wrapper img").each(function () {
				//$(this).height(t - 40)
			})
		});
	}

	function equalblockheight() {
		$(".awardrec-wrap .list-wrap ul li .outer-block").equalize();
		$(".awardrec-wrap .list-wrap .list-wrap-award .outer-block").equalize();
	}

	function cabmemberheight() {
		var e = $(".home-lrdthgh-slider.cab-member-wrap .owl-item .media .media-body").outerHeight();
		if ($(".home-lrdthgh-slider.cab-member-wrap .owl-item .media .media-left .img-wrapper img").each(function () {
			$(this).height(e)
		}), $(window).width() >= 767) {
			var t = -1;
			$(".home-lrdthgh-slider.cab-member-wrap .owl-item .media .media-body").each(function () {
				t = t > $(this).height() ? t : $(this).height()
			}), $(".home-lrdthgh-slider.cab-member-wrap .owl-item .media .media-left .img-wrapper").each(function () {
				$(this).height(t)
			})
		}
	}

	function solutioncsr() {
		if ($(window).width() >= 991) {
			var e = -1;
			$(".solution-areas-slider .csr-abt-res .media .media-body").each(function () {
				e = e > $(this).outerHeight() ? e : $(this).outerHeight()
			}), $(".solution-areas-slider .csr-abt-res .media .media-left .img-wrapper img").each(function () {
				$(this).height(e)
			})
		} else {
			var t = -1;
			$(".solution-areas-slider .csr-abt-res .media .media-body").each(function () {
				t = t > $(this).height() ? t : $(this).height()
			}), $(".solution-areas-slider .csr-abt-res .media .media-body").each(function () {
				$(this).height(t)
			})
		}
	}

	function casestudyheight() {
		if ($(window).width() >= 767) {
			var e = -1;
			$(".case-study-card .card-info-box").each(function () {
				e = e > $(this).outerHeight() ? e : $(this).outerHeight()
			}), $(".case-study-card .card-info-box").each(function () {
				$(this).height(e)
			})
		}
	}

	function cabyearcardheight() {
		if ($(window).width() >= 768) {
			var e = -1;
			$(".cab-post-feature > li .inner.cab-agenda .equal-height,.cab-post-feature .owl-item .inner.cab-agenda .equal-height").each(function () {
				e = e > $(this).height() ? e : $(this).height()
			}), $(".cab-post-feature > li .inner.cab-agenda .equal-height,.cab-post-feature .owl-item .inner.cab-agenda .equal-height").each(function () {
				$(this).height(e)
			})
		}
	}

	function cabmem() {
		var e = $(".memeber-list .media > div.media-left").height();
		var t = 0;
		$(".home-lrdthgh-slider .owl-item .media").each(function () {
			t = t > $(this).height() ? t : $(this).height()
		});
		$(".memeber-list .media > div .img-wrapper").each(function () {
			$(this).height(t)
		});
		$(".home-lrdthgh-slider .owl-item .media .media-left .img-wrapper img").each(function () {
			$(this).height(t)
		})
	}

	function titleheight() {
		if ($(window).width() >= 768) {
			var e = -1;
			$(".career-list-wrap .career-searchlst-wrap .list-wrap ul li h5.job-title").each(function () {
				e = e > $(this).height() ? e : $(this).height()
			}), $(".career-list-wrap .career-searchlst-wrap .list-wrap ul li h5.job-title").each(function () {
				$(this).height(e)
			})
		}
	}

	function contpost() {
		var e = $(window).width(), t = ($(".container"), $(".container").offset().left - 15),
			a = $(".container").offset().left + 15;
		e >= 768 && ($(".owl-nav .owl-prev").css("left", t), $(".owl-nav .owl-next").css("right", t), $(".home-video-wrap .custom-container .hv-widget-area .media_image-4").css({width: "calc(100% - " + a + "px)"}))
	}

	function msieversion() {
		return (window.navigator.userAgent.indexOf("MSIE ") > 0 || navigator.userAgent.match(/msie|Trident.*rv[ :]*11\./gi) || navigator.userAgent.match(/Edge\/\d./)) && (Modernizr.objectfit || $(".head-banner").each(function () {
			var e = $(this), t = e.find("img").prop("src");
			t && e.css("backgroundImage", "url(" + t + ")").addClass("compat-object-fit")
		})), !1
	}

	function menuposition() {
		$(window).width() > 767 && $("header.header .navbar-nav > li").hover(function () {
			$("header.header .navbar-nav .dropdown-menu").each(function () {
				1 == $(this).children(".yamm-content").children(".row").children().length ? $(this).addClass("oneli") : $(this).removeClass("oneli")
			})
		})
	}

	function floatingLabel() {
		$(".float-label input").each(function () {
			var e = $(this).attr("placeholder"), t = $(this).parent().parent().find("label").text();
			void 0 !== e && "" !== e || $(this).attr("placeholder", t)
		}), $(".float-label input").bind("input propertychange", function (e) {
			"" !== $(e.target).val() ? $(e.target).parent().parent().find("label").addClass("header-label") : $(e.target).parent().parent().find("label").removeClass("header-label")
		})
	}

	function GetIEVersion() {
		var e = window.navigator.userAgent, t = e.indexOf("MSIE");
		return t > 0 ? parseInt(e.substring(t + 5, e.indexOf(".", t))) : navigator.userAgent.match(/Trident\/7\./) ? 11 : 0
	}

	$(function () {
		$(".question-and-ans li h3").on("click", function (e) {
			$(this).find("span").text("See more" == $(this).find("span").text() ? "See less" : "See more"), $(this).find("i").toggleClass("fa-long-arrow-down fa-long-arrow-up")
		}), $(".checklist-list li a").attr("download", " "), $(".info-graphics-list .see-less").on("click", function (e) {
			$(this).parent().removeClass("active"), $(".info-graphics-container .tab-content li").removeClass("active in")
		}), $(".info-graphics-list a.visible-xs.visible-sm").on("click", function (e) {
			$(this).find("span").text("See more" == $(this).find("span").text() ? "See less" : "See more"), $(this).find("i").toggleClass("fa-long-arrow-down fa-long-arrow-up")
		});
		var e = $(".primary-tabs");
		$(".primary-tabs li").on("click", function (t) {
			t.preventDefault();
			var a = $(this), i = a.offset().left + a.outerWidth(!0) / 2 + e.scrollLeft() - e.width() / 2;
			e.scrollLeft(i)
		}), $(function () {
			if (!$('body').hasClass("page-leadership-team")) {
				$(document).on("shown.bs.tab", 'a[data-toggle="tab"]', function (e) {
					localStorage.setItem("activeTab", $(e.target).attr("href"))
				});
				var e = window.location.hash, t = localStorage.getItem("activeTab");
				e ? $('a[href="' + e + '"]').tab("show") : t && $('a[href="' + t + '"]').tab("show")
			}
		}), $(".social-media-list.common-social ul.menu li.menu-l a").jsSocials({shares: ["linkedin"]}), $(".social-media-list.common-social ul.menu li.menu-t a").jsSocials({shares: ["twitter"]}), $(".social-media-list.common-social ul.menu li.menu-f a").jsSocials({shares: ["facebook"]}), "shown" !== localStorage.getItem("popState") && ($("#cookie-msg").fadeIn(), localStorage.setItem("popState", "shown"), $("#cookie-msg").delay(2e4).fadeOut()), $("#cookie-msg > a").click(function () {
			$("#cookie-msg").hide()
		}), $(document).on("click", function (e) {
			$(e.target).closest(".job-search-key .job-search-field .career-form .form-group .input-value, .input-state, .input-city, .input-experience, .input-expertise, .input-location").length || ($(".job-search-key .job-search-field .career-form .form-group .outer-wrap").removeClass("text-transit"), $(".expertise, .country, .state, .city, .experience-list").addClass("hidden"))
		}), $(function () {
			if (a > 767) {
				$("li.load-more-list").slice(0, 3).show();
				var e = $("ul > li.load-more-list:hidden").length + $("ul > li.load-more-list:visible").length;
				3 !== e && e > 3 ? $("#loadMore").show() : $("#loadMore").hide(), $("#loadMore").on("click", function (e) {
					e.preventDefault(), $("li.load-more-list:hidden").slice(0, 3).slideDown(), 0 === $("li.load-more-list:hidden").length && $("#loadMore").fadeOut("slow"), $("html,body").animate({scrollTop: $("#ourpeople").offset().top - 600}, 1500)
				})
			} else $("li.load-more-list").slice(0, 2).show(), $("#loadMore").on("click", function (e) {
				e.preventDefault(), $("li.load-more-list:hidden").slice(0, 2).slideDown(), 0 === $("li.load-more-list:hidden").length && $("#loadMore").fadeOut("slow"), $("html,body").animate({scrollTop: $("#ourpeople").offset().top - 600}, 1500)
			})
		}), $(".primary-tabs > li:first-child").addClass("active"), $(".tab-content > div:first-of-type").addClass("active"), $(document).on("click", function (e) {
			$(e.target).closest(".navbar .navbar-nav > li.dropdown").length || $(".sub-dots").removeClass("active")
		}), $(".navbar .navbar-nav > li").prepend('<span class="sub-dots"><span class="red"></span><span class="yellow"></span><span class="blue"></span></span>'), $(window).width() > 500 && ($(".navbar .navbar-nav > li.dropdown").on("click", function () {
			$(this).find(".sub-dots").toggleClass("active"), eligheight()
		}), $(".navbar .navbar-nav > li.dropdown").mouseover(function () {
			eligheight()
		})), $(window).width() <= 767 && $("header.header ul.nav li.dropdown a.dropdown-toggle").on("click", function () {
			$(this).next().slideToggle(), $(this).toggleClass("toggle-open")
		});
		var t = $(".custom-header-banner .banner-overlay .banner-intro a");
		t.length >= 0 && (t.wrap("<div class='btn-wrap'></div>"), t.addClass("btn btn-more")), $(".navbar .navbar-nav > li.dropdown").hover(function () {
			eligheight()
		});
		var a = $(window).width();
		$(window).on("scroll", function () {
			a > 767 && ($(window).scrollTop() > 80 ? ($("header").addClass("sticky"), $("header .navbar-brand .white-logo").addClass("hidden"), $("header .navbar-brand .black-logo").removeClass("hidden")) : ($("header").removeClass("sticky"), $("header .navbar-brand .white-logo").removeClass("hidden"), $("header .navbar-brand .black-logo").addClass("hidden")))
		}), a < 767 && ($("header .navbar-brand .white-logo").addClass("hidden"), $("header .navbar-brand .black-logo").removeClass("hidden"), $(window).on("scroll", function () {
			$(window).scrollTop() > 80 ? $("header").addClass("sticky") : $("header").removeClass("sticky")
		})), $("header.header nav.navbar .navbar-header button.navbar-toggle").click(function () {
			$("header.header nav.navbar .navbar-collapse").slideToggle(), $(this).toggleClass("opens"), $("html,body").toggleClass("menu-opens")
		}), $(".post-card.col-post-8, .post-card.col-post-4, .post-card.subscribe-card").equalize();
		var i = $(".scrollbTop a");
		$(window).scroll(function () {
			$(this).scrollTop() > 100 ? $(i).css("opacity", "1") : $(i).css("opacity", "0")
		}), $(i).click(function () {
			return $("html, body").animate({scrollTop: 0}, 1e3), !1
		}), $(".map-container .our-location ul.location-list > li:nth-child(1) ul.post-title, .map-container .our-location ul.location-list > li:nth-child(2) ul.post-title").addClass("submenu"), $(".location-dropdown").click(function () {
			$(this).next("ul.post-title").slideToggle(), $(this).children("i").toggleClass("icon-up icon-down"), $(this).parents("li").siblings().children(".location-dropdown").next("ul.post-title.submenu").slideUp()
		}), $(".post-title > li").mouseover(function () {
			$(this).children().next().addClass("open")
		}), $(".post-title > li").mouseout(function () {
			$(this).children().next().removeClass("open")
		}), $("#location45").hide().removeClass("location-dropdown"), $(".location-dropdown").append('<i class="icon10 icon-down"></i>'), msieversion(), menuposition(), $(".afs-FilterPanel1 h5").text("More in news"), $(".afs-TableWrapper").addClass("gridPR"), $(".fulltext").hide(), $(".blog-item").append('<a href="#" class="moretxt"><span class="text">More Details</span><i class="fa fa-angle-down" aria-hidden="true"></i></a>'), $(".blog-item .moretxt").click(function (e) {
			e.preventDefault(), $(this).prev(".fulltext").slideToggle("slow"), $(this).children(".text").text("Less Details" === $(this).text() ? "More Details" : "Less Details"), $(this).children("i").toggleClass("fa-angle-down fa-angle-up"), $(".media-body > img").toggleClass("img-bt-marg")
		}), $(".moretxt").click(function () {
			return $(this).hasClass("sample") ? ($(this).removeClass("sample"), $(this).find(".text").text(showtxt), $(this).find(".fa").toggleClass("fa-angle-down fa-angle-up")) : ($(this).addClass("sample"), $(this).find(".text").text(hidetxt), $(this).find(".fa").toggleClass("fa-angle-down fa-angle-up")), $(this).parent().prev().fadeToggle("slow"), $(this).prev().fadeToggle("slow"), !1
		});
		var o = $(".cab-yeardetail-wrap .media .media-body img").height();

		function s(e) {
			$(e.target).prev(".partner-benefits-wrap .panel-heading").find(".more-less").toggleClass("fa-angle-down fa-angle-up")
		}

		$(".cab-yeardetail-wrap .media .text-flow-wrap .read-more").css("min-height", o - 120), floatingLabel(), $(".career-banner-wrap .career-top-sec .job-search-key .job-search-field .career-form .form-group.country-wrap select").on("change", function () {
			$(".career-banner-wrap .career-top-sec .job-search-key .job-search-field .career-form .form-group.city-wrap").removeClass("hidden-op"), $(this).parents(".outer-wrap").toggleClass("text-transit")
		}), $(".career-banner-wrap .career-top-sec .job-search-key .job-search-field .career-form .form-group select").on("change", function () {
			$(this).parents(".outer-wrap").toggleClass("text-transit")
		}), $(".clicksdismis").on("click", function (e) {
			e.preventDefault(), $(this).parents("li").removeClass("li-expand"), $(this).parents("li").find(".expand-sec, .clicksdismis").addClass("hidden"), $(this).parents("li").find(".collapse-sec").removeClass("hidden"), $(".clicks").removeClass("hidden"), $(".list-wrap ul li h4, .list-wrap ul li h5").addClass("trunc-heading")
		}), $(".clicks").on("click", function (e) {
			e.preventDefault(), $(this).parents("li").addClass("li-expand"), $(this).parents("li").find(".collapse-sec").addClass("hidden"), $(this).parents("li").find(".expand-sec, .clicksdismis").removeClass("hidden"), $(".clicks").addClass("hidden"), $(".list-wrap ul li h4, .list-wrap ul li h5").removeClass("trunc-heading")
		}), $(".leadership-grid li.leadership-block h4, .leadership-grid li.leadership-block .media .media-left .hover-img-wrap").on("click", function (e) {
			e.preventDefault(), $(this).parents("li").toggleClass("active")
		}), $(".leadership-grid li.leadership-block .clickdismis").on("click", function (e) {
			e.preventDefault(), $(this).parents("li").removeClass("active")
		}), $(".awardrec-wrap .list-wrap ul li .block .img-wrap img").each(function () {
			$(this).attr("alt") && (this.alt, $(this).attr("gartner-01"), $(this).parents("li").find(".gartner-note").removeClass("hidden"))
		}), $(".partner-benefits-wrap .panel-group").on("hidden.bs.collapse", s), $(".partner-benefits-wrap .panel-group").on("shown.bs.collapse", s), $(".chat-board a").on("click", function () {
			$("body").css({overflow: "visible", "padding-right": "0"})
		}), $(".footer-toggle-link").on("click", function () {
			$(this).next("ul.menu").slideToggle(), $(this).children(".icon").find("i").toggleClass("fa-angle-down fa-angle-up")
		}), cabmem()
	}), $(document).ready(function () {
		carousalmaxheight(), cabmemberheight(), cabmem(), $(".container");
		var e = $(".container").offset().left + 15;

		$(".home-lrdthgh-slider .owl-next").hide();
		$(".home-lrdthgh-slider").owlCarousel({
			center: !0,
			items: 1,
			nav: !0,
			smartSpeed: 1e3,
			autoplay: true,
			autoplayTimeout: 15000,
			navText: [" ", " "],
			loop: !1,
			margin: 30,
			stagePadding: e,
			rewind: true,
			responsive: {0: {items: 1}, 768: {items: 1}, 1366: {items: 1}},
			onInitialize:function() {
				$( '.home-lrdthgh-slider .owl-prev' ).hide();
			},
			onInitialized:function() {
				$( '.home-lrdthgh-slider .owl-prev' ).hide();
			},
			onTranslated: function() {
				if( $( '.owl-carousel.home-lrdthgh-slider .owl-item' ).last().hasClass( 'active') ) {
					$( '.home-lrdthgh-slider .owl-prev' ).show();
					$( '.home-lrdthgh-slider .owl-next' ).hide();
				}else if($('.owl-carousel.home-lrdthgh-slider .owl-item').first().hasClass('active')){
					$( '.home-lrdthgh-slider .owl-next').show();
					$( '.home-lrdthgh-slider .owl-prev').hide();
				} else {
					$( '.home-lrdthgh-slider .owl-prev,.home-lrdthgh-slider .owl-next' ).show();
				}
			}
		}), $(".csr-rsp-slider").owlCarousel({
			stagePadding: 110,
			loop: !0,
			margin: -50,
			smartSpeed: 1e3,
			nav: !0,
			items: 1,
			lazyLoad: !0,
			navText: [" ", " "],
			responsive: {
				320: {items: 1, margin: 20, stagePadding: 0},
				768: {items: 1, margin: 20, stagePadding: 0},
				1e3: {items: 1},
				1200: {items: 1},
				1400: {items: 1},
				1600: {items: 1},
				1800: {items: 1}
			}
		}), $(".solution-areas-slider").owlCarousel({
			loop: !1,
			margin: 20,
			smartSpeed: 1e3,
			nav: !0,
			items: 1,
			lazyLoad: !0,
			navText: ["", ""]
		}), $(".partner-talk-slider").owlCarousel({
			items: 1,
			margin: 30,
			stagePadding: 0,
			smartSpeed: 1e3,
			loop: !1,
			nav: !0,
			navText: ["<i class='icon48 icon-navhollow-left'></i>", "<i class='icon48 icon-navhollow-right'></i>"],
			responsive: {0: {autoHeight: !0}, 768: {autoHeight: !1}}
		}), $(".meet-our-experts-slider").owlCarousel({
			center: !1,
			items: 2,
			nav: !0,
			loop: !1,
			stagePadding: e,
			smartSpeed: 1e3,
			margin: 30,
			responsive: {0: {items: 1}, 767: {items: 2}, 992: {items: 3}}
		}), $(".owl-carousel.event").owlCarousel({
			center: !1,
			items: 2,
			nav: !0,
			stagePadding: e,
			smartSpeed: 1e3,
			navText: [" ", " "],
			margin: 30,
			responsive: {0: {items: 1}, 767: {items: 2}, 992: {items: 3}}
		}), $(".owl-carousel.image-video-block").owlCarousel({
			center: !1,
			items: 1,
			nav: !0,
			stagePadding: e,
			smartSpeed: 1e3,
			navText: [" ", " "],
			margin: 30,
			responsive: {0: {items: 1}, 767: {items: 1}}
		}), $(".owl-carousel.life-at-fr-carousel").owlCarousel({
			center: !1,
			items: 1,
			nav: !0,
			stagePadding: e,
			smartSpeed: 1e3,
			navText: [" ", " "],
			margin: 30,
			responsive: {0: {items: 1}, 767: {items: 1}}
		}), $(".image-popup-vertical-fit").magnificPopup({
			type: "image",
			closeOnContentClick: !0,
			mainClass: "mfp-img-mobile",
			image: {verticalFit: !0}
		}),$(".popup-youtube, popup-vimeo,  .popup-gmaps").magnificPopup({
			disableOn: 700,
			type: "iframe",
			mainClass: "mfp-fade",
			removalDelay: 160,
			preloader: !1,
			fixedContentPos: !1
		}), $(".popup-gallery").magnificPopup({
			delegate: "a",
			type: "image",
			tLoading: "Loading image #%curr%...",
			mainClass: "mfp-img-mobile",
			gallery: {enabled: !0, navigateByImgClick: !0, preload: [0, 1]},
			image: {
				tError: '<a href="%url%">The image #%curr%</a> could not be loaded.', titleSrc: function (e) {
					return e.el.attr("title") + "<small>by Marsel Van Oosten</small>"
				}
			}
		}), $(".magnific-gallery").each(function (e, t) {
			for (var a = $(this), i = $(this).data("links").split(","), o = [], s = 0; s < i.length; s++) o.push({
				src: i[s],
				title: ""
			});
			a.magnificPopup({
				mainClass: "mfp-fade",
				items: o,
				gallery: {enabled: !0, tPrev: $(this).data("prev-text"), tNext: $(this).data("next-text")},
				type: "image"
			})
		}), contpost(), $(".newsroom-wrap ul.more-newslist li .block, .afs-Loadingdata, .realted-post-wrap ul li .block").equalize(), $(".newsroom-wrap ul.more-newslist li").hover(function () {
			$(".newsroom-wrap ul.more-newslist li .block").equalize()
		}), setTimeout(function () {
			$(".typed1").typed({
				stringsElement: $("#typed-strings1"),
				autoStart: !1,
				typeSpeed: 30,
				backDelay: 750,
				loop: !1,
				loopCount: !1,
				callback: function () {
				}
			})
		}, 0), setTimeout(function () {
			$(".typed2").typed({
				stringsElement: $("#typed-strings2"),
				autoStart: !1,
				typeSpeed: 30,
				backDelay: 750,
				loop: !1,
				loopCount: !1,
				callback: function () {
				}
			})
		}, 2e3), setTimeout(function () {
			$(".typed3").typed({
				stringsElement: $("#typed-strings3"),
				autoStart: !1,
				typeSpeed: 30,
				backDelay: 750,
				loop: !1,
				loopCount: !1,
				callback: function () {
				}
			})
		}, 4e3), setTimeout(function () {
			$(".typed4").typed({
				stringsElement: $("#typed-strings4"),
				autoStart: !1,
				typeSpeed: 30,
				backDelay: 750,
				loop: !1,
				loopCount: !1,
				callback: function () {
				}
			})
		}, 6e3), setTimeout(function () {
			$(".typed5").typed({
				stringsElement: $("#typed-strings5"),
				autoStart: !1,
				typeSpeed: 30,
				backDelay: 750,
				loop: !1,
				loopCount: !1,
				callback: function () {
				}
			})
		}, 8e3), $(".icheck input").iCheck({
			labelHover: !1,
			cursor: !0,
			checkboxClass: "icheckbox_minimal-yellow",
			radioClass: "iradio_minimal-yellow"
		}), $("select:not(#hear-about-us)").selectric({disableOnMobile: false, nativeOnMobile: false})
	}), $(window).on("load", function () {
		titleheight(), eligheight1(), cabyearcardheight(), $(".cab-post-feature > li .inner.cab-agenda .btn-wrap .btn").on("click", function () {
			$(this).parents(".inner.cab-agenda").toggleClass("cab-agenda-pos"), $(this).parents(".inner.cab-agenda").children(".desc-wrap").toggle()
		}), $(".cab-post-feature > li .desc-wrap .clickdismis").on("click", function () {
			$(this).parents(".inner.cab-agenda ").toggleClass("cab-agenda-pos"), $(this).parents(".desc-wrap").toggle()
		}), $(".career-banner-wrap .career-top-sec .job-search-key .job-search-field .career-form .form-group.country-wrap .outer-wrap .selectric-wrapper .selectric-items ul").each(function () {
			$(this).find("li").each(function () {
				var e = $(this).text().toLowerCase();
				$(".career-banner-wrap .career-top-sec .job-search-key .job-search-field .career-form .form-group.country-wrap .outer-wrap .selectric-wrapper .selectric-items ul li:first()").attr("id", "all"), $(this).attr("id", e.replace(/ /g, ""))
			})
		}), $(".career-banner-wrap .career-top-sec .job-search-key .job-search-field .career-form .form-group .selectric").on("click", function () {
			$(this).parents(".outer-wrap").toggleClass("text-transit")
		}), $(".career-banner-wrap .career-top-sec .job-search-key .job-search-field .career-form .form-group.country-wrap .outer-wrap .selectric-wrapper .selectric-items ul li").attr("class", "all"), $(".career-banner-wrap .career-top-sec .job-search-key .job-search-field .career-form .form-group.country-wrap .outer-wrap .selectric-wrapper .selectric-items ul li").click(function () {
			var e = this.id;
			return "all" === e ? $(".career-banner-wrap .career-top-sec .job-search-key .job-search-field .career-form .form-group.city-wrap .outer-wrap .selectric-wrapper").find(".selectric-items ul li.all").show() : ($(".career-banner-wrap .career-top-sec .job-search-key .job-search-field .career-form .form-group.city-wrap .outer-wrap .selectric-wrapper").find(".selectric-items ul li").hide(), $(".career-banner-wrap .career-top-sec .job-search-key .job-search-field .career-form .form-group.city-wrap .outer-wrap .selectric-wrapper").find(".selectric-items ul li.all." + e).show()), !1
		}), $(".what-we-do").equalize(), $(window).width() > 768 && ($(".info-graphics-list").equalize(), $(".text-analytics-list > li > .panel .description").equalize()), cabmem(), casestudyheight(), solutioncsr(), carousallength();
		var e = $(".wp-main-container").height();
		$(".vertical-outer, .vertical-outer ul").height(e), contpost(), $(".post-card.col-post-8, .post-card.col-post-4, .post-card.subscribe-card").equalize(), equalblockheight()
	}), $(window).resize(function () {
		cabmem(), cabmemberheight();
		var e = $(".wp-main-container").height();
		$(".vertical-outer, .vertical-outer ul").height(e), contpost(), carousalmaxheight(), $(".post-card.col-post-8, .post-card.col-post-4, .post-card.subscribe-card").equalize(), menuposition(), equalblockheight(), $(".newsroom-wrap ul.more-newslist li .block, .afs-Loadingdata, .realted-post-wrap ul li .block").equalize(), $(".newsroom-wrap ul.more-newslist li").hover(function () {
			$(".newsroom-wrap ul.more-newslist li .block").equalize()
		})
	}), $(window).on("scroll", function () {
		var e = $(".wp-main-container").height();
		$(".vertical-outer, .vertical-outer ul").height(e), carousalmaxheight(), menuposition()
	}), $.fn.equalize = function () {
		if ($(window).width() > 667) {
			var e = 0;
			return this.each(function () {
				var t = $(this);
				t.height() > e && (e = t.height())
			}), this.each(function () {
				$(this).height(e)
			})
		}
		return !1
	}, $(document).ready(function () {
		var e = $("section.in-case-miss-wrapper .missed-block").length, t = 12;
		$("section.in-case-miss-wrapper .row > .missed-block:lt(" + t + ")").show(), $("#loadMores").click(function (a) {
			a.preventDefault(), t = t + 3 <= e ? t + 3 : e, $("section.in-case-miss-wrapper .row > .missed-block:lt(" + t + ")").show(), $("html,body").animate({scrollTop: "+=300px"}), $("section.in-case-miss-wrapper .missed-block:visible").length === e && $("#loadMores").hide()
		});

		var per_page     = 3;
		var current_item = 6;
		if ($(window).width() < 768) {
			per_page     = 2;
			current_item = 3;
		}

		var upcoming_webinar = $("section.upcoming-webinars .upcoming-webinars__webinar").length;
		upcoming_webinar <= per_page && $("#loadMore-upcomding-webinar").hide(),
		$("section.upcoming-webinars .upcoming-webinars__webinar-list > .upcoming-webinars__webinar:lt(" + per_page + ")").show(),
			$("section.upcoming-webinars .upcoming-webinars__webinar:visible").css( 'border-bottom', '1px solid #eee' ),
			$("section.upcoming-webinars .upcoming-webinars__webinar:visible:last").css( 'border-bottom', '0px' ),
			$("#loadMore-upcomding-webinar").click(function (a) {
			a.preventDefault(),
			per_page = per_page + 1 <= upcoming_webinar ? per_page + 1 : upcoming_webinar,
			$("section.upcoming-webinars .upcoming-webinars__webinar-list > .upcoming-webinars__webinar").show(),
				$("html,body").animate({scrollTop: "+=300px"}),
				$("section.upcoming-webinars .upcoming-webinars__webinar:visible").css( 'border-bottom', '1px solid #eee' )
				$("section.upcoming-webinars .upcoming-webinars__webinar:visible:last").css( 'border-bottom', '0px' ),
				$("section.upcoming-webinars .upcoming-webinars__webinar:visible").length === upcoming_webinar && $("#loadMore-upcomding-webinar").hide()
		});

		var past_webinar = $("section.past-webinars .past-webinars__webinar").length;
		$("section.past-webinars .row > .past-webinars__webinar:lt(" + current_item + ")").show(),
			past_webinar <= current_item && $("#loadMore-past-webinar").hide(),
			$("#loadMore-past-webinar").click(function (a) {
				a.preventDefault(),
					current_item = current_item + 3 <= past_webinar ? current_item + 3 : past_webinar,
					$("section.past-webinars .row > .past-webinars__webinar").show(),
					$("html,body").animate({scrollTop: "+=300px"}),
				$("section.past-webinars .past-webinars__webinar:visible").length === past_webinar && $("#loadMore-past-webinar").hide()
			});

		if ( $(window).width() > 768 ) {
			var webinar_content_height = 20;
			jQuery( '.past-webinars .past-webinars__webinar-description' ).each( function() {
				if ( jQuery( this ).outerHeight() > webinar_content_height ) {
					webinar_content_height = $( this ).outerHeight();
				}
			} );
		}

		jQuery( '.past-webinars .past-webinars__webinar-description' ).css( 'height', webinar_content_height );

		var a = $(".event-list-wrapper > li").length, i = 6,
			o = $(".event-list-wrapper > li:hidden").length + $(".event-list-wrapper > li:visible").length;
		3 !== o && o > 3 ? $("#loadMores1").show() : $("#loadMores1").hide(), $(".event-list-wrapper > li:lt(" + i + ")").show(), $("#loadMores1").click(function (e) {
			e.preventDefault(), i = i + 3 <= a ? i + 6 : a, $(".event-list-wrapper > li:lt(" + i + ")").show(), $("html,body").animate({scrollTop: "+=250px"}, 1e3), $(".event-list-wrapper > li:visible").length === a && $("#loadMores1").hide()
		}), $("ul.row.event-list-wrapper ~ #loadMores1").on("click", function () {
			$(".event-scrolltop").removeClass("hidden")
		});
		var s = $(".cab-post-feature").length, r = 1,
			n = $(".cab-post-feature > li:hidden").length + $(".cab-post-feature > li:visible").length;
		3 !== n && n > 3 ? $("#loadMores2").show() : $("#loadMores2").hide(), $(".cab-post-feature:lt(" + r + ")").show(), $("#loadMores2").click(function (e) {
			e.preventDefault(), r = r + 1 <= s ? r + 1 : s, $(".cab-post-feature:lt(" + r + ")").show(), $("html,body").animate({scrollTop: "+=250px"}, 1e3), $(".cab-post-feature:visible").length >= s && $("#loadMores2").hide()
		}), $(window).width() < 768 && $(".our-people-section > li.people-list").on("click", function () {
			$(this).children(".people-card").children(".normal-state").find(".full-desc").toggle()
		})
	}), jQuery(document).ready(function (e) {
		function t(t) {
			e([e(".cd-filter-trigger"), e(".cd-filter"), e(".cd-tab-filter"), e(".cd-gallery")]).each(function () {
				e(this).toggleClass("filter-is-visible", t)
			})
		}

		GetIEVersion() > 0 && e("body").attr("id", "ie-body"), e(".cd-filter-trigger").on("click", function () {
			t(!0)
		}), e(".cd-filter .cd-close").on("click", function () {
			t(!1)
		});
		var a = e(".cd-tab-filter .placeholder a"), i = a.text();
		e(".cd-tab-filter li").on("click", function (t) {
			var o = e(t.target).data("type");
			e(t.target).is(a) ? ("Select" == a.text() ? a.text(i) : a.text("Select"), e(".cd-tab-filter").toggleClass("is-open")) : a.data("type") == o ? (a.text(e(t.target).text()), e(".cd-tab-filter").removeClass("is-open")) : (e(".cd-tab-filter").removeClass("is-open"), a.text(e(t.target).text()).data("type", o), i = e(t.target).text(), e(".cd-tab-filter .selected").removeClass("selected"), e(t.target).addClass("selected"))
		}), e(".cd-filter-block h4").on("click", function () {
			e(this).toggleClass("closed").siblings(".cd-filter-content").slideToggle(300)
		})
	}), document.addEventListener("wpcf7invalid", function (e) {
		$(".wpcf7-response-output").addClass("alert alert-success"), $(".wpcf7-response-output").parents(".form-respond-popup").addClass("form-respond-popup-wrap"), $(".wpcf7-response-output").parents(".model-content").children(".dismiss-icon").removeClass("hidden"), $(".form-respond-popup.form-respond-popup-wrap .model-content a").on("click", function () {
			$(this).next(".wpcf7-response-output").addClass("ritu"), $(this).next(".wpcf7-response-output").removeClass("wpcf7-mail-sent-ok").hide(), $(this).parents(".form-respond-popup").removeClass("form-respond-popup-wrap"), $(this).addClass("hidden")
		})
	}, !1), document.addEventListener("wpcf7spam", function (e) {
		$(".wpcf7-response-output").addClass("alert alert-success"), $(".wpcf7-response-output").parents(".form-respond-popup").addClass("form-respond-popup-wrap"), $(".wpcf7-response-output").parents(".model-content").children(".dismiss-icon").removeClass("hidden"), $(".form-respond-popup.form-respond-popup-wrap .model-content a").on("click", function () {
			$(this).next(".wpcf7-response-output").addClass("ritu"), $(this).next(".wpcf7-response-output").removeClass("wpcf7-mail-sent-ok").hide(), $(this).parents(".form-respond-popup").removeClass("form-respond-popup-wrap"), $(this).addClass("hidden")
		})
	}, !1), document.addEventListener("wpcf7mailfailed", function (e) {
		$(".wpcf7-response-output").addClass("alert alert-success"), $(".wpcf7-response-output").parents(".form-respond-popup").addClass("form-respond-popup-wrap"), $(".wpcf7-response-output").parents(".model-content").children(".dismiss-icon").removeClass("hidden"), $(".form-respond-popup.form-respond-popup-wrap .model-content a").on("click", function () {
			$(this).next(".wpcf7-response-output").addClass("ritu"), $(this).next(".wpcf7-response-output").removeClass("wpcf7-mail-sent-ok").hide(), $(this).parents(".form-respond-popup").removeClass("form-respond-popup-wrap"), $(this).addClass("hidden")
		})
	}, !1), document.addEventListener("wpcf7mailsent", function (e) {
		$(".wpcf7-response-output").addClass("alert alert-success"), $(".wpcf7-response-output").parents(".form-respond-popup").addClass("form-respond-popup-wrap"), $(".wpcf7-response-output").parents(".model-content").children(".dismiss-icon").removeClass("hidden"), $(".form-respond-popup.form-respond-popup-wrap .model-content a").on("click", function () {
			$(this).next(".wpcf7-response-output").addClass("ritu"), $(this).next(".wpcf7-response-output").removeClass("wpcf7-mail-sent-ok").hide(), $(this).parents(".form-respond-popup").removeClass("form-respond-popup-wrap"), $(this).addClass("hidden")
		})
	}, !1);
}(jQuery));
